import React from 'react';

class Palindrome extends React.Component {
	constructor() {
        super();
        this.state = {}
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        let imagePath = '_images/hero/';
	    
        return (
            <section className="page">
                <article className="page__article">
                    <header>
                        <h2>Palindrome 2020</h2>
                        <p><em>palindrome</em> a word, sentence etc. which reads the same backward and forward</p>
                    </header>
                    <div className="page__article--content">
                        <img src={imagePath + 'hero-palindrome.jpg'} alt="" />
                        <p>When the 2nd day of the 2nd month in 2020 occurred, a special event took place. A self fulfilling numerical palindrome that could be read backwards or forwards in either day-month-year, or month-day-year. In year-day-month, or in year-month-day. This unusual phenomenon has not been experienced for over 900 years, since the 11th day of the 11th month of 1111. A timely statistical oddity which also occurred on the Sunday of Groundhog Day 02-02-2020, and when you add 02-02-2020 it will equal 8. The number 8 is an infinity loop which  visually represents God in either vertical, or horizontal configurations. The very significant Godlike 8 is also the only integer in numerology which when added to itself equals the one as in 8x8=64, or 10 drop the zero remaining as the intuitive number one. Even more significance is to be found in the fact that the multiples of the divine number 8 move in a backward direction as in 8, 16, 24, 32, 40 etc., reducing in the single digit to 8, 7, 6, 5, 4, 3, 2, 1, 9, 8…</p>
                        <br />
                        <p>Perhaps in this Language of the Sole there is a hidden message? A Spiritually Supernatural, Inter-dimensional Force sending out an Infinite and Outer-worldly wish. A vibrational transmission to encourage Mankind to practise an ongoing regimen of balance and consideration for the Universe when it comes to our current technological advancements. The continual discipline of common sense in order to allow oneself the required time to look back on past mistakes as we gaze into our future. A home where we all look forward to a more responsible role regarding our Humanity and what kind of Environment we would like to live in. A Global Philosophy that Cherishes Sustainability over Monetary Gain, for all of the money in the world is of no value to you without your health in which to spend it. A Future Shining Brightly for all of us as we consider the lives of the Unfortunate Children that are yet to be born. This Vision that promotes a Harmonious Horizon concerning the State of Well Being for our Mother Earth.</p>
                        <br />
                        <p>Of course therein lies the Crux, which would be that all of the do gooders, and well wishers on this Planet will not add up to a hill of beans if Mankind is not able to Decipher the Celestial Language of the Sole. The God-Matrix Math Key is able to unlock the grip of this Digital and Virtual Reality that has Transfixed our Behaviour into a State of Depravity. Your Heavenly Blessed Spirit, and your Eternal Sole Rite is trying it’s very Damnedest to Open the Door to your Fragile Heart, and a Gate to the Everlasting. The Sad Reality is that without an Ability to Know Who We Are, and a Skillset of Numeracy allowing us to Solve the Simple Problems, we will surely be Doomed to a Paradigm of Purgatory. There are none so Blind as those who Cannot See and none so Deaf as those who Cannot Hear.</p>
                        <br />
                        <p>It is now the Time for Mankind to Become Aware of this Immanent fact, that all of the Human Desires for Motion and Progress are Not always Forward. We need to Justify our Actions immediately while we still can. Initiate This Trend now, before it becomes Much Too Late, when we have Completely Decimated our Environment into a Cesspool of Non-existence unto the point of no return.</p>
                        <br />
                        <p>On a much brighter, and even more artistic note, the interesting God-like Palindrome date sequence that I had mentioned earlier, reminded me of my Third Work of Visual Art in a Trilogy Series of Three. These early renderings were instigated when I was not only contemplating and visualizing the numbers, but decided to begin painting the numbers as well. This simple Artistic Analog Act brought back the joy and significance which I once received from My Analog Music before the Digitalization Process removed the Vibration and stripped the Heart and Soul from the Art Form. The third sketch previously mentioned was drawn with just a blue ballpoint pen, and a black magic marker on white office paper and titled Functions of the Spirit, Know Yourself 11-11-11. My first attempt at Numerical Art was called The Twelve Crosses Of God 3-3-3, and dated October 21, 2011. My second serious work of art I now called The Three Windows Of The Soul 1-1-1, and it was dated November 1, 2011. I did not even notice at the time, but all Three Pieces in this First Art Trilogy were performed exactly 10 days apart each, equaling 30 days. This is the total number of days in every single month on the early Mosaic Calendar, with each month of the year containing 30 days. Instead of adding a leap year every 4 years as in the Roman Calendar, the Mosaic Calendar adds 1 extra month every 3 years. This earlier Calendar allowed for more Balance, and Human Stability due to its connection with Man’s Circadian Rhythm patterns, while the Full Moon infinitely took place on the 15th day of every month like clockwork. The Interval Ratio date spacing for my Art Trilogy was completely unintentional, but nonetheless highly fascinating and interestingly unusual. Since I have painted this newest artwork titled Palindrome 2020, I am now signing all of my pieces on the bottom right hand corner with MD20.</p>
                        <br />
                        <p>Around the same time that I was beginning to write, paint and do the math, I was also garnering significance from the Single Digit Derivative’s of the dates that I would read on the face of the pennies that I would find. Although, for some unknown reason people would regularly throw their pennies away like they were worthless. An act of disrespecting the money, while going against time honoured traditions, and sayings like “A penny saved is a penny earned”, and “Watch your pennies and the pounds will watch themselves”. This was before the Canadian Government abolished our Penny. An act that made no Common Cents, pardon the pun. As if our Youth did not already have enough Problems in Counting, we had to now begin Rounding Numbers. A Small Adjustment to the currency  which reaped Large Problems for our ability to Visualize the Numbers causing Difficulty when Counting, creating a further Imbalance for the State of Mankind.</p>
                        <br />
                        <p>I also find it interesting that Britain, and America did not take away they’re Blessed Pennies. Do they know something that we do not, or is it that we are just too stupid. The first thing that I would do if I had my way would be to Reinstate the Penny. So what if our pennies costs 2 pennies to produce, they last forever. If it is really just about the cost of copper, use a different metal. A Change of this Magnitude would speak Volumes towards the Mental Well Being of the Community, and it would also make a lot of Common Sense. Come on now, let’s get with the program. Make a difference, what do you have left to lose, when there is so much that can be gained. Sorry about my little rant and transgression, but the whole penny issue has been a great Cause of Concern for me. It is a good time for some of the things which can be more readily changed without too much difficulty, to go back to the more Logical Ways of Old. A Renaissance of Ideology if you will, in order for Man to sit back and Reflect on his Past before he jumps too far ahead of himself in the current Machine State Paradigm.</p>
                        <br />
                        <p>Now where was I, Oh yes my newfound evolution in the expansion of my Liberal Arts took place after writing my first thesis titled “The Digitalization of Man” in 2010. I wrote this Manifesto to make people become more aware of how the complete change of all Analog technologies to Digital communicative devices was beginning to make Mankind more Machine-like. I also wanted to introduce the newly discovered Matrix Language of Lost Math that I had been able to somehow Channel from the Wise Old Soul of the Greek Philosopher known as Pythagoras.</p>
                        <br />
                        <p>This Matrix Language was in Essence Born of a Single Digit Numerological, Vibrational Math consisting of the Seven Note Musical Solfeggio Scale, in the Frequencies of Hertz which are Radio Waves, in A=432Hz Concert Pitch, and in the 9 space format of Greek Magic Squares. Coming to me out of the thin air as if I were writing a song, I devised this Matrix of Perfection by Axiomatically adding the 2 frequencies of 285Hz and 174Hz in a backwards direction before the start of the scale at Do(396Hz) in order to change the 7 note musical scale into a 9 note, 3 digit vibrational scale, and Matrix Math Language. Performing the Exercise of this Vibrational Mathematical Practise will surely Realign the Neural Plasticity of your Dual Hemisphered Analog Brain Patterns, back into the Naturalistic Human State in which it was Ergonomically Intended to perform. To learn more about the Single Digit Pythagorean Numerology and the Man known as Pythagoras you may reference my 9 page instructional paper “The Musicology of Numerology” at www.iAmDillon.com for an introduction into this enlightening subject matter.</p>
                        <br />
                        <p>Being Born under the Birth Rite of the 3rd day of the 3rd month has always made me feel as though 3 was a lucky number for me, and at the turn of the Century I began to develop a deeper fascination towards the numbers. I began to believe that the 3rd Day of the 3rd Month in the 3rd Year of the 3rd Millennium was going to a very special day for me. I signed a poem 03-03-03, and in 2005 I signed my first speech “Forces At Work” with 05-05-05. In 2010 I signed my first thesis “The Digitalization of Man” with the date of 10-10-10, which I also interpreted as being a lucky year because 2010 is equal to 3. My second thesis “Functions of the Spirit” was signed using 11-11-11, and my third manuscript “Journey of the Soul” was signed and dated 12-12-12, which I also thought of as being lucky because 1+2=3. There you have in a nut shell, and that is kind of how the 9 single integers of numerology began to provide me with volumes of significance, awareness, and fascination.</p>
                    </div>
                    <div className='poem__article--content'>
                        <div className="youtube">
                            <iframe
                                title="Factious"
                                width="560"
                                height="315"
                                src="https://www.youtube.com/embed/R8YchItbaXw"
                                controls=""
                                frameBorder="0"
                                allow="accelerometer; encrypted-media; gyroscope;"
                                allowFullScreen
                            />
                        </div>
                        
                        <h2>Factious</h2>
                        
                        How can I feel like I’m alright when I<br />
                        Try to feel for myself with my health<br />
                        Why would I ever want to lie when I<br />
                        Can’t believe in myself or my wealth<br />
                        <br />
                        Cause its a long way down now I’m feeling sad<br />
                        I’ve got it good but I want it bad<br />
                        Your the best thing that I’ve ever had myself<br />
                        For my health<br />
                        <br />
                        I can’t find the way, I won’t live the day<br />
                        When its high I’m low, If its fast I’m slow<br />
                        I’ll not get it back, when its blue I’m black<br />
                        If I cry then pray, too live just one more day<br />
                        <br />
                        What was I thinking losing dreams of my<br />
                        Simple plans of just making it right and never lose the sight<br />
                        When did I give up all those things that I<br />
                        Always felt would just be making it right, and never lose the fight<br />
                        <br />
                        So now when its day well I say its night<br />
                        Knowing I’m wrong but I feel alright <br />
                        Theres nothing left for me thats in sight of the truth<br />
                        To ever bring me back<br />
                        <br />
                        Theres just one more thing, please help to make me sing<br />
                        When I’m wrong it’s not right, if I’m black that's not white<br />
                        This time I’ll not go, there’s something you should know<br />
                        Believe me when I say, I won’t live that way
                    </div>
                </article>
            </section>
    	);
	}
};
export default Palindrome;