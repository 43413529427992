import React from 'react';
import Button from '../components/Button';
import Video from '../components/Video';

class BookPageDetail extends React.Component {
    constructor() {
        super();
        this.state = {}
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        const { data, cClass, divClick, mediaClick, title, content, id } = this.props
        let imagePath = '/_images/auntisocial/';
        let videoPath = '_video/';

        const header = (
            <header>
                <h2>{data.title}</h2>
                <p><em>Sociology</em> the study of the origin, the history and the structure of human society and it’s institutions</p>
            </header>
        )

        const article = (
            <>
                <div className={cClass}>
                    {data.articleHero ? <img src={imagePath + data.articleHero} alt="" /> : ''}
                    {data.type === 'video'
                        ?
                            <Video
                                poster={imagePath + data.poster}
                                source={videoPath + data.source}
                                type={data.sourceType}
                                controls={true}
                                controlsList="nodownload"
                            />
                        : ''
                    }
                    {data.articleTitle ? <h3>{data.articleTitle}</h3> : ''}
                    {data.articleContent ? <p>{data.articleContent}</p> : ''}
                </div>
                <span> {this.props.page}</span>
            </>
        )

        const controls = (
            <div className='auntisocial__controls'>
                <Button
                    cClass='btn btn__back'
                    click={
                        () => this.props.prev()
                    }
                    icon={true}
                    title='Previous'
                />
                <Button
                    cClass='btn btn__text'
                    click={
                        () => this.props.close()
                    }
                    icon={true}
                    title='Index'
                />
                <Button
                    cClass='btn btn__next'
                    click={
                        () => this.props.next()
                    }
                    icon={true}
                    title='Next'
                />
            </div>
        )

        return (
            <>
            {header}
            {article}
            {controls}
            </>
        )
    }
};
export default BookPageDetail;